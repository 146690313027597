#login h1 a {
  background-image: url("../../assets/images/skw-logo.svg");
  background-size: contain;
  width: auto;
  background-position: center;
}

.login input[type=password]:focus,
.login input[type=text]:focus,
.login input[type=checkbox]:focus {
  border-color: #DBCE69;
  box-shadow: 0 0 0 1px #DBCE69;
}

.login input[type=checkbox]:checked::before {
  content: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%23000000%27%2F%3E%3C%2Fsvg%3E");
}

.login .message {
  border-left: 4px solid #DBCE69;
}

.wp-core-ui .button-primary {
  background-color: #DBCE69;
  border-color: #DBCE69;
}
.wp-core-ui .button-primary:hover, .wp-core-ui .button-primary:focus {
  background-color: #d1c140;
  border-color: #d1c140;
}
.wp-core-ui .button-primary:focus {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #DBCE69;
}

.login .button.wp-hide-pw:focus {
  border-color: #DBCE69;
  box-shadow: 0 0 0 1px #DBCE69;
}

.login .dashicons-visibility:before {
  color: rgba(82, 104, 142, 0.1019607843);
}