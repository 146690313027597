// Settings
// -------------------------
$grid-columns:          12;
$enable-negative-margins: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1600px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * 0.25), // 4px
  2: ($spacer * 0.5), // 8px
  standard: ($spacer * 0.75), // 12px
  3: $spacer, // 16px
  4: ($spacer * 1.5), // 24px
  5: ($spacer * 2), // 32px
  6: ($spacer * 2.5), // 40px
  7: ($spacer * 3), // 48px
  8: ($spacer * 4), // 64px
  9: ($spacer * 4.5), // 72px
  10: ($spacer * 5), // 80px
  11: ($spacer * 7.5), // 120px
  12: ($spacer * 10), // 160px
  13: ($spacer * 12.5), // 200px
  p20: 20%,
  px180: 180px,
);

// Brand colors
// -------------------------

// Styleguide colors
$color-body: #171717;
$color-asphalt-grey: #616161;
$color-blue: #52688E;
$color-blue-10: #52688E1A;
$color-blue-darker: #405272;
$color-white: #ffffff;
$color-lime: #DBCE69;
$color-dark-lime: #BCB057;
$color-error: #FF0000;



$color-default: $color-body;
$color-primary: $color-lime;
$color-secondary: $color-blue;
$color-tertiary: $color-blue-10;
$color-primary-d: $color-blue-darker;
$color-white: $color-white;
$color-grey: $color-asphalt-grey;

// bootstrap variables override
$primary:       $color-primary;
$secondary:     $color-secondary;

// text
$headings-margin-bottom: $spacer;

// theme colors
$theme-colors: (
  "primary": $color-primary,
  "primary-d": $color-primary-d,
  "default": $color-default,
  "secondary": $color-secondary,
  "tertiary": $color-tertiary,
  "white": $color-white,
  "grey": $color-grey,
  "danger": $color-error,
  "none": transparent,
) !default;

$headings: (
  3xl: 4.5rem,
  xxl: 4rem,
  xl: 3.5rem,
  lg: 3rem,
  md: 2.5rem,
  sm: 2rem,
  xs: 1.5rem,
  xxs: 1.25rem,
  3xs: 1.125rem,
  4xs: 1rem,
  5xs: .875rem,
  6xs: 0.75rem
);

$body: (
  xl: 1.25rem,
  lg: 1.125rem,
  md: 1rem,
  sm: 0.875rem,
  xs: 0.75rem,
);

$lineHeights: (
  none: 1,
  body: 1.5,
  heading: 1.2
);

$utilities: (
  "headings": (
    property: font-size,
    class: heading,
    responsive: true,
    values: $headings,
  ),
  "body": (
    property: font-size,
    class: body,
    responsive: true,
    values: $body,
  ),
  "line-height": (
    property: line-height,
    class: lh,
    values: $lineHeights
  ),
  "text": (
    property: color,
    class: text,
    state: hover,
    responsive: true,
    values: $theme-colors,
  ),
  "bg": (
    property: background-color,
    class: bg,
    state: hover,
    responsive: true,
    values: $theme-colors,
  ),
  "hyphens": (
    property: hyphens,
    class: hyphens,
    responsive: true,
    values: (
      none: none,
      manual: manual,
      auto: auto,
    ),
  ),
  "position": (
    property: position,
    responsive: true,
    values: static relative absolute fixed sticky initial inherit
  ),
  "height": (
    property: height,
    class: h,
    responsive: true,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  ),
  "width": (
    property: width,
    class: w,
    responsive: true,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  ),
  "max-width": (
    property: max-width,
    class: mw,
    responsive: true,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
    ),
  ),
);

// --- Buttons ---
$input-btn-padding-y: 0.938rem !default;
$input-btn-padding-x: 1.313rem !default;
$btn-border-radius: 4px!default;
$btn-border-width: 3px!default;
$btn-font-weight: bold!default;
$btn-font-size: 1rem!default;
$btn-font-size-sm: 1rem!default;
$btn-font-size-lg: 1rem!default;
$btn-transition:  color .3s ease-out, background-color .3s ease-out, border-color .3s ease-out, box-shadow .3s ease-out;

// --- Badges ---
$badge-font-size:                   1.125em;
$badge-font-weight:                 bold;
$badge-color:                       $color-white;
$badge-padding-y:                   0.5em;
$badge-padding-x:                   1em;
$badge-border-radius:               3px;

// -- Forms --
// Checkbox
$form-check-input-width:                  1.25em!default;
$form-check-input-border:                 1px solid #616161;

// Input
$input-padding-y:                       8px!default;
$input-padding-x:                       16px!default;
$input-font-size:                       1rem;
$input-font-weight:                     400;
$input-line-height:                     1.5;

$input-bg:                              $color-white;
$input-disabled-bg:                     #EEEEEE;
$input-disabled-border-color:           null;

$input-color:                           $color-body;
$input-border-color:                    $color-grey;
$input-border-width:                    1px;
$input-box-shadow:                      null;

$input-border-radius:                   4px;
$input-border-radius-sm:                4px;
$input-border-radius-lg:                4px;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              #52688E;
$input-focus-color:                     #171717;
$input-focus-width:                     1px;
$input-focus-box-shadow:                null;

$input-placeholder-color:               $color-body;
$input-plaintext-color:                 $color-body;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5);

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-color-width:                      3rem;

$font-size-md: 1.125rem !default;

$accordion-button-active-bg:            $color-white;
$accordion-button-active-color:         tint-color($color-secondary, 90%);
$accordion-button-focus-box-shadow:     shade-color($color-secondary, 10%);

$accordion-body-padding-y:              1.2rem;
$accordion-body-padding-x:              1.3rem;

$accordion-button-padding-y:            1.5rem;
$accordion-button-padding-x:            1.3rem;
