#login h1 a {
  background-image: url('../../assets/images/skw-logo.svg');
  background-size: contain;
  width: auto;
  background-position: center;
}

.login input[type="password"]:focus,
.login input[type="text"]:focus,
.login input[type="checkbox"]:focus {
  border-color: $color-primary;
  box-shadow: 0 0 0 1px $color-primary;
}

.login input[type="checkbox"]:checked::before {
  content: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%3Cpath%20d%3D%27M14.83%204.89l1.34.94-5.81%208.38H9.02L5.78%209.67l1.34-1.25%202.57%202.4z%27%20fill%3D%27%23000000%27%2F%3E%3C%2Fsvg%3E");
}

.login .message {
  border-left: 4px solid $color-primary;
}

.wp-core-ui .button-primary {
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover, &:focus {
    background-color: darken($color-primary, 10);
    border-color: darken($color-primary, 10);
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-white, 0 0 0 3px $color-primary;
  }
}

.login .button.wp-hide-pw:focus {
  border-color: $color-primary;
  box-shadow: 0 0 0 1px $color-primary;
}

.login .dashicons-visibility:before {
  color: $color-tertiary;
}
